import styled, { css } from 'styled-components/macro'
import { device, ColorsMap, FontSizeMap } from '@/utils/Theme'

export const StyledMain = styled.div`
  width: 86%;
  margin: 5rem 7%;
  max-width: ${device.desktop};
`

export const HeadlineWithAction = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  margin-bottom: 0.5rem;

  @media (max-width: ${device.tablet}) {
    flex-direction: column;
  }
`

export const Action = styled.a`
  color: ${ColorsMap.textDefault};
  align-self: center;
  margin-left: 1.5rem;

  &:hover {
    color: ${ColorsMap.brownishGrey};
  }

  @media (max-width: ${device.tablet}) {
    margin: 0;
    margin-top: 0.5rem;
    align-self: flex-start;
  }
`

export const BannerWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  margin: 5rem auto;
`

export const SplitBanner = styled.div`
  position: relative;
  display: grid;
  //grid-template-columns: 2fr 1fr;
  width: 100%;
  margin: 5rem 0;

  @media (max-width: ${device.tablet}) {
    //grid-template-columns: 1fr;
    //grid-template-rows: 20rem;
  }
`

export const SplitBannerWrapper = styled.a`
  position: relative;
  display: block;
  width: 100%;
`

type BannerProps = { fullHeight?: boolean }
export const Banner = styled.img<BannerProps>`
  display: block;
  width: 100%;
  object-fit: cover;
  ${({ fullHeight }) => fullHeight && 'height: 100%;'}
`

type BannerButtonWrapperProps = { combined?: boolean }
export const BannerButtonWrapper = styled.div<BannerButtonWrapperProps>`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 0);

  @media (max-width: ${device.mobile}) {
    width: 100%;
    bottom: 1rem;
    display: flex;
    justify-content: center;

    * {
      max-height: 3rem;
      font-size: ${FontSizeMap.s};
    }
  }

  ${({ combined }) => {
    if (combined) {
      return css`
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (min-width: ${device.mobile}) {
          padding: 0 10%;
        }
        @media (max-width: ${device.mobile}) {
          * {
            margin: 0 1rem;
          }
        }
        @media (max-width: ${device.mobileS}) {
          * {
            max-width: 8rem;
            margin: 0;
            margin: 0 auto;
          }
        }
      `
    }
  }}
`

