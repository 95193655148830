import styled from 'styled-components/macro'
import { device, ColorsMap } from '@/utils/Theme'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledProductArea = styled.div`
  width: 100%;
  display: grid;
  justify-items: center;
  position: relative;

  @media (min-width: ${device.tablet}) {
    grid-template-areas:
      'banner1 banner1 product1 product2'
      'product3 product4 banner2 banner2';
  }

  @media (max-width: ${device.tablet}) {
    grid-template-areas:
      'banner1 banner1'
      'product1 product2'
      'banner2 banner2'
      'product3 product4';
  }
  @media (max-width: ${device.mobileS}) {
    grid-template-areas:
      'banner1'
      'product1'
      'product2'
      'banner2'
      'product3'
      'product4';
  }
`

export const BrandWrapper = styled.div`
  position: relative;
  height: 1rem;

  > img {
    height: 100%;
    opacity: 0.5;
    transition: 0.3s;
  }
`

export const ProductBox = styled.div`
  position: relative;
  cursor: pointer;
  min-width: 15rem;
  width: 100%;
  margin: 0.5rem auto;
  margin-left: 0;
  background-color: ${ColorsMap.white};
  padding: 1rem;
  @media (max-width: ${device.mobileS}) {
    width: 100%;
    margin: 0.5rem 0;
  }
  &:hover {
    ${BrandWrapper} > img {
      opacity: 1;
    }
  }
`

export const ProductImageWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 14rem;
  * {
    max-width: 80%;
    height: auto;
    max-height: 100%;
  }
`
export const DescriptionWrapper = styled.div`
  margin: 1rem;
  max-width: 100%;
  * {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`

export const ButtonWrapperLeft = styled.button`
  position: absolute;
  height: 30%;
  top: 50%;
  transform: translate(0, -50%);
  left: 0.2%;
`
export const ButtonWrapperRight = styled.button`
  position: absolute;
  height: 30%;
  top: 50%;
  transform: translate(0, -50%);
  right: 0.2%;
`
export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border: 1px solid;
`

type PageControllProps = {
  controller?: boolean
}
export const PagesControll = styled.div<PageControllProps>`
  display: ${(props) => {
    return props.controller ? 'flex' : 'none'
  }};
  color: ${ColorsMap.fontHover};
  margin-left: auto;
  margin-right: 4%;
`

type NumProps = {
  controller?: boolean
}
export const Num = styled.div<NumProps>`
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  background-color: ${(props) => {
    return props.controller ? 'rgb(80, 144, 80, 0.1)' : 'none'
  }};
  border-radius: 0.25rem;
`

export const Banner = styled.img`
  display: block;
  width: 96%;
  margin: auto 2%;
  object-fit: cover;
`
