import React from 'react'
import Carousel from 'react-multi-carousel'

import {
  ShowOrderPart,
  SliderContentWrapper,
  BannerWrapper,
} from './SliderStyles'

export interface BannerChangerProps {
  sliderContent?: any
}
export interface StylesProps {
  color?: string
  activeSlide?: boolean
}

export const BannerChanger: React.FC<BannerChangerProps> = ({
  children,
  sliderContent,
}) => {
  const responsive = {
    mobile: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  }

  const CustomDot = ({ onMove, index, onClick, active }) => {
    return (
      <ShowOrderPart
        allSlides={index + 1}
        activeSlide={active}
        className={active ? 'active' : 'inactive'}
        onClick={() => {
          return onClick()
        }}
      />
    )
  }

  return (
    <Carousel
      responsive={responsive}
      infinite
      showDots
      slidesToSlide={1}
      arrows={false}
      autoPlay
      interval={4000}
      customDot={<CustomDot />}
    >
      {sliderContent.map((data, id) => {
        return (
          <BannerWrapper key={id}>
            <img
              style={{
                objectFit: 'cover',
                height: 'auto',
                width: '100%',
                pointerEvents: 'none',
                userSelect: 'none',
              }}
              src={data.image}
              alt=""
            />
            <SliderContentWrapper>{data.content}</SliderContentWrapper>
          </BannerWrapper>
        )
      })}
    </Carousel>
  )
}
