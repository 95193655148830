import React, { useRef, useEffect, useState } from 'react'
import Link from 'next/link'
import Carousel from 'react-multi-carousel'
import useTranslation from 'next-translate/useTranslation'

import { Button } from '../Button'
import Icons from '../Icons'
import {
  ButtonWrapperLeft,
  ButtonWrapperRight,
  Box,
  ShowOrderPart,
  SliderContentWrapper,
  LogoImg,
} from './SliderStyles'

export type VariantType = 'default' | 'instant'

export interface SliderProps {
  sliderContent?: any
  position?: number
  variant?: VariantType
  clickedImageIndex?: any
}
export interface StylesProps {
  color?: string
  activeSlide?: boolean
}

export const sliderVariant = {
  DEFAULT: 'default',
  INSTANT: 'instant',
}

export const Slider: React.FC<SliderProps> = ({
  children,
  sliderContent,
  // position = 1,
  variant,
  clickedImageIndex,
  ...props
}) => {
  const productData = [
    {
      image: '/images/banners/landingpage_banner_eshop.jpg',
      content: null,
      link: 'https://sintrasporteshop.sk/',
      linkText: null,
    },
    {
      image: '/images/banners/landingpage_banner_sale_red.png',
      content: null,
      link: 'https://sintrasporteshop.sk/kategoria-produktu/vypredaj/',
      linkText: null,
    },
    {
      image: '/images/banners/landingpage_banner_hummel.jpg',
      content: (
        <LogoImg>
          <img src="/images/logos/hummel-rect.svg" style={{ width: '100%' }} alt="Hummel banner" />
        </LogoImg>
      ),
      linkText: null,
    },
    {
      image: '/images/banners/landingpage_banner_p2i.jpg',
      content: (
        <LogoImg>
          <img src="/images/logos/pure-2-improve-w.svg" style={{ width: '100%' }} alt="Pure 2 improve banner" />
        </LogoImg>
      ),
      linkText: null,
    },
    {
      image: '/images/banners/landingpage_banner_kids.jpg',
      content: null,
      link: 'https://sintrasporteshop.sk/kategoria-produktu/oblecenie/deti/',
      linkText: null,
    },
  ]

  const responsive = {
    mobile: {
      breakpoint: { max: 3000, min: 0 },
      items: 1,
    },
  }
  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest
    if (variant === sliderVariant.INSTANT) {
      return (
        <>
          <ButtonWrapperLeft
            {...props}
            className={currentSlide === 0 ? 'disable' : ''}
            onClick={() => previous()}
            ml="5%"
          >
            <Button variant="verticalRad" colorVariant="primary" borderNone>
              <Icons.ChevronLeft />
            </Button>
          </ButtonWrapperLeft>
          <ButtonWrapperRight {...props} onClick={() => next()} mr="5%">
            <Button variant="verticalRad" colorVariant="primary" borderNone>
              <Icons.ChevronRight />
            </Button>
          </ButtonWrapperRight>
        </>
      )
    }

    return (
      <>
        <ButtonWrapperLeft {...props} className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()}>
          <Button variant="verticalRad" colorVariant="primaryWhite" borderNone height="100%">
            <Icons.ChevronLeft />
          </Button>
        </ButtonWrapperLeft>
        <ButtonWrapperRight {...props} onClick={() => next()}>
          <Button variant="verticalRad" colorVariant="primaryWhite" borderNone height="100%">
            <Icons.ChevronRight />
          </Button>
        </ButtonWrapperRight>
      </>
    )
  }
  const CustomDot = ({ onMove, index, onClick, active, variant }) => (
    <ShowOrderPart
      allSlides={index + 1}
      activeSlide={active}
      className={active ? 'active' : 'inactive'}
      variant={variant}
      onClick={() => {
        return onClick()
      }}
    />
  )

  const carouselRef = useRef()

  if (variant === sliderVariant.INSTANT) {
    useEffect(() => {
      carouselRef.current.goToSlide(clickedImageIndex + 1)
    }, [clickedImageIndex])
    return (
      <Carousel
        ref={carouselRef}
        responsive={responsive}
        swipeable
        infinite
        keyBoardControl
        minimumTouchDrag={80}
        showDots
        slidesToSlide={1}
        arrows={false}
        customDot={<CustomDot variant="instant" />}
        customButtonGroup={<ButtonGroup />}
      >
        {sliderContent.map((image, i) => (
          <Box key={i} variant={variant}>
            <img src={image.url} alt={image.alt ? image.alt : ''} />
          </Box>
        ))}
      </Carousel>
    )
  }

  return (
    <Carousel
      responsive={responsive}
      infinite
      swipeable
      keyBoardControl
      minimumTouchDrag={80}
      showDots
      slidesToSlide={1}
      arrows={false}
      autoPlay
      interval={4000}
      customDot={<CustomDot />}
      customButtonGroup={<ButtonGroup />}
    >
      {sliderContent
        ? sliderContent.map((data, i) => (
            <Box key={i} variant="instant">
              <img
                style={{
                  objectFit: 'cover',
                  height: 'auto',
                  width: '100%',
                  position: 'absolute',
                  userSelect: 'none',
                }}
                src={data.image}
                alt=""
              />
              <SliderContentWrapper>{data.content}</SliderContentWrapper>
            </Box>
          ))
        : productData.map((data, i) =>
            data.link && !data.linkText ? (
              <Link href={data.link} passHref>
                <Box as="a" key={i} draggable="false">
                  <img
                    draggable="false"
                    style={{
                      objectFit: 'cover',
                      height: '100%',
                      width: '100%',
                      position: 'absolute',
                      userSelect: 'none',
                    }}
                    src={data.image}
                    alt=""
                  />
                  <SliderContentWrapper>{data.content}</SliderContentWrapper>
                </Box>
              </Link>
            ) : (
              <Box key={i}>
                <img
                  draggable="false"
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                    position: 'absolute',
                  }}
                  src={data.image}
                  alt=""
                />
                <SliderContentWrapper>{data.content}</SliderContentWrapper>
              </Box>
            )
          )}
    </Carousel>
  )
}

