import React from 'react'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { Button } from '@/components/Button'
import { Loader } from '@/components/Loader'
import { BannerWrapper, BannerButtonWrapper } from '@/components/PageParts/HomeMain/MainStyles'
import { ProductCard } from '@/components/Product/ProductCard'

import { ProductInterface } from '@/types/Product'

import { StyledProductArea, Wrapper, Banner } from './ProductAreaStyles'

const IndexProductArea: React.VFC = () => {
  const { t } = useTranslation('common')

  if (false) {
    return (
      <Wrapper>
        <Loader m="auto" />
      </Wrapper>
    )
  }

  return (
    <Wrapper id="ProductsArea">
      <StyledProductArea>
        <BannerWrapper style={{ gridArea: 'banner1' }}>
          <Banner src="/images/banners/homepage-banner-1.jpg" />
          <BannerButtonWrapper>
            <Link href="https://sintrasporteshop.sk/kategoria-produktu/obuv/" passHref>
              <Button as="a" variant="horizontalRad" colorVariant="transparent" height="100%" uppercase>
                {t('shop-now')}
              </Button>
            </Link>
          </BannerButtonWrapper>
        </BannerWrapper>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <BannerWrapper style={{ gridArea: 'banner2' }}>
          <Banner src="/images/banners/homepage-banner-2.jpg" />
          <BannerButtonWrapper>
            <Link href="https://sintrasporteshop.sk/kategoria-produktu/sportove-potreby/batohy-a-tasky-sportove-potreby/" passHref>
              <Button as="a" variant="horizontalRad" colorVariant="transparent" height="100%" uppercase>
                {t('shop-now')}
              </Button>
            </Link>
          </BannerButtonWrapper>
        </BannerWrapper>
      </StyledProductArea>
    </Wrapper>
  )
}

export default IndexProductArea

