import styled, { css } from 'styled-components/macro'
import { space, layout } from 'styled-system'

import { ColorsMap, device } from '@/utils/Theme'
import { SliderProps, StylesProps } from './Slider'

const getBoxStyles = ({ variant }: SliderProps) => {
  if (variant === 'instant') {
    return css`
      padding: 3rem 0;
    `
  }
  return css`
    img {
      width: 100%;
      height: 100%;
      pointer-events: none;
      user-select: none;
    }
  `
}

export const ButtonWrapperLeft = styled.button`
  position: absolute;
  height: 25%;
  top: 50%;
  transform: translate(0, -50%);
  left: 0.2%;
  ${space}
`
export const ButtonWrapperRight = styled.button`
  position: absolute;
  height: 25%;
  top: 50%;
  transform: translate(0, -50%);
  right: 0.2%;
  ${space}
`
export const Box = styled.div<SliderProps>`
  min-width: 100%;
  height: 35rem;
  z-index: -1;
  display: flex;
  justify-content: center;
  ${(props) => props.variant === 'instant' && 'padding: 5rem;'}

  @media (max-width: ${device.tablet}) {
    height: 30rem;
  }

  @media (max-width: ${device.mobile}) {
    height: 23rem;
  }

  @media (max-width: ${device.mobileS}) {
    height: 18rem;
  }

  > img {
    object-fit: scale-down;
  }
`

export const BannerWrapper = styled.div``

export const ShowOrderPart = styled.div<StylesProps>`
  width: 8%;
  height: 0.2rem;
  background-color: ${(props) => (props.variant === 'instant' ? ColorsMap.brownishGrey : ColorsMap.white)};
  margin: 1rem 0;
  transition: all 1s ease;
  cursor: pointer;
  opacity: ${(props) => {
    return props.activeSlide ? '1' : '0.2'
  }};
`

export const SliderContentWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 8rem;
  transform: translate(0, -50%);
  max-width: 30%;
  z-index: 1;
  @media (max-width: ${device.mobile}) {
    display: none;
  }
`

export const LogoImg = styled.div`
  margin-left: 5rem;

  ${space}
  ${layout}
`

