import React from 'react'
import { connect } from 'react-redux'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

import { Text } from '@/components/Text'
import { IndexProductArea } from '@/components/Page/Index/IndexProductArea'

import { Action, HeadlineWithAction, StyledMain } from './MainStyles'
import { ColorsMap } from '@/utils/Theme'

import { StateInterface } from '@/types/State'

const Main = () => {
  const { t } = useTranslation('common')

  return (
    <StyledMain>
      <HeadlineWithAction>
        <Text textColor={ColorsMap.primary} textTransform="uppercase" fontSize="xl" fontWeight="bold">
          {t('new-arrivals')}
        </Text>
      </HeadlineWithAction>
      <IndexProductArea />
    </StyledMain>
  )
}

const mapStateToProps = (state: StateInterface) => {
  return {
    state,
  }
}

export default connect(mapStateToProps)(Main)

