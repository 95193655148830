import React from 'react'
import { GetServerSidePropsContext } from 'next'
import { connect } from 'react-redux'
import useTranslation from 'next-translate/useTranslation'
import Main from '@/components/PageParts/HomeMain'

import { Slider } from '@/components/Slider'

import { initializeStore } from '@/redux/store'
import { getProducts, getSettings } from '@/redux/actions'
import { PageLayout } from '@/components/Layout/PageLayout'
import { StateInterface } from '@/types/State'

const IndexPage = ({ state }) => {
  const { t } = useTranslation('common')
  return (
    <PageLayout title={`${state.settings.name} | ${t('title:index')}`} description={t('seo:description.index')}>
      <Slider />
      <Main />
    </PageLayout>
  )
}

const mapStateToProps = (state: StateInterface) => {
  return {
    state,
  }
}

export async function getServerSideProps(ctx: GetServerSidePropsContext) {
  const reduxStore = initializeStore({})
  const { dispatch } = reduxStore

  const token = ctx.req.cookies.token || null

  await dispatch(getSettings({ token }))
  await dispatch(getProducts({ token, random: true }))
  return { props: { initialReduxState: reduxStore.getState() } }
}

export default connect(mapStateToProps)(IndexPage)
